<template>
    <div class="login-box">
        <div class="login-wrap">
            <div class="content">
                <div class="main">
                    <div class="main-left">
                        <el-carousel indicator-position="outside" height="349px" arrow="never">
                            <el-carousel-item v-for="(item,index) in bannerList" :key="index">
                            <img :src="item.img" alt="">
                            </el-carousel-item>
                        </el-carousel>
                    </div>
                    <div class="main-right">
                        <div class="main-right-logo">
                            <img src="../assets/img/loginIcon/logo.png" alt="">
                        </div>
                        <div>
                            <div class="input">
                                <div class="input-icon">
                                    <img src="../assets/img/loginIcon/menber-icon.png" alt="">
                                </div>
                                <input type="text" placeholder="请输入手机号码" v-model="phone">
                            </div>
                            <div class="code">
                                <div class="code-icon">
                                    <img src="../assets/img/loginIcon/code-icon.png" alt="">
                                </div>
                                <input type="text" maxlength="6" placeholder="请输入短信验证码" v-model="code">
                                <button @click="sendCode()">{{isRun?`${runTime}s后重新获取`:'获取验证码'}}</button>
                            </div>
                            <div class="input">
                                <div class="input-icon">
                                    <img src="../assets/img/loginIcon/password-icon.png" alt="">
                                </div>
                                <form>
                                    <input type="password" placeholder="请输入您的新密码" v-model="password" autocomplete="off">
                                </form>
                                <div class="forgetIcon">
                                    <img :src="seen?openeye:closeeye" @click="showPwd">
                                </div>
                            </div>
                            <div class="checkConfim">
                                <input type="checkbox" class="checkbox" v-model="checked"><p>请阅读并选择<a href="javascript:;" @click="dialogTableVisible = true">《网校云课程服务协议》</a></p>
                            </div>
                        </div>
                        <div class="login-button">
                            <button @click="register()">注册</button>
                        </div>
                        <div class="login-register">
                            <span>已有账号吗？<router-link to="/Login" class="register">立即登录</router-link></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <el-dialog title="《网校云课程服务协议》" :visible.sync="dialogTableVisible" >
            <p v-html="treatyText"></p>
        </el-dialog>
    </div>
</template>
<script>
export default {
    name: 'register',
    data() {
        return {
            bannerList: [
                {
                    img: require('../../public/img/index/live.png')
                },
                {
                    img: require('../../public/img/index/bank.png')
                },
                {
                    img: require('../../public/img/index/video.png')
                }
            ],
            phone: '',
            code: '',
            password: '',
            checked: false, 
            // 倒计时
            isRun: false,
            runTime: 60,
            dialogTableVisible: false,
            treatyText: '',
            seen: '', // 图片显示
            openeye: require('../assets/img/loginIcon/password-show.png'),
            closeeye: require('../assets/img/loginIcon/password-hide.png')
        }
    },
    mounted() {
        this.getTreaty();
    },
    metaInfo: {
        title: '注册-网校', // set a title
        meta: [
            {             // set meta
                name: 'keyWords',
                content: '我是contact关键字'
            },
            {
                name: 'description',
                content: '我是contact描述'
            }
        ]
    },
    methods: {
        // checkphone() {
        //     this.axios({
        //         method: 'POST',
        //         url: '/yun/login/check_account_exist.html',
        //         data: {
        //             phoneNumber: this.phone,
        //         }
        //     }).then((res) => {
        //         if(res.data.code == 200) {
        //             // window.alert("请保证手机号码正确")
        //             console.log(4)
        //             return;
        //         }
        //     })
        // },
        getTreaty(){
            this.axios({
            method: 'GET',
            url: 'api/v2/yun/login/get_service_agreement.html'
            }).then((res) => {
                this.treatyText = res.data.result.serviceAgreement;
            })
        },
        showPwd (){
            this.typePwd = this.typePwd === 'password' ? 'text' : 'password';
            this.seen = !this.seen;
        },
        async sendCode() {
            if(this.isRun) return;
            if(!/^1\d{10}$/.test(this.phone)){
                window.alert("请保证手机号码正确")
                return;
            }
            // await this.checkphone();
            this.axios({
                method: 'POST',
                url: 'api/v2/yun/login/get_sms_code.html',
                data: {
                    phoneNumber: this.phone,
                }
            })
            this.isRun = true;
            this.autoTimer = setInterval(() => {
                if(this.runTime === 0) {
                    this.runTime = 60;
                    this.isRun = false;
                    clearInterval( this.autoTimer);
                    return;
                }
                this.runTime--;
            }, 1000);
        },

        register() {
            this.axios({
                method: 'POST',
                url: 'api/v2/yun/login/register_by_phone.html',
                data: {
                    phoneNumber: this.phone,
                    smsCode: this.code,
                    password: this.password
                }
            }).then((res) => {
                if(res.data.code == 200) {
                    this.$router.push('../pages/index');
                } else {
                    alert(res.data.msg)
                }
            })
        }
    }
}
</script>
<style lang="scss" scoped>
        .el-dialog {
            display: flex;
            flex-direction: column;
            margin:0 !important;
            position:absolute;
            top:50%;
            left:50%;
            transform:translate(-50%,-50%);
            /*height:600px;*/
            max-height:calc(100% - 200px);
            max-width:calc(100% - 30px);
        }
        .el-dialog .el-dialog__body {
            flex:1;
            overflow: auto;
        }
        .el-dialog__header {
            text-align: center;
            .el-dialog__title {
                color: #FF5A74;
            }
        }
    .login-box {
        position: relative;
        .login-wrap {
            padding-top: 92px;
            width: 100%;
            min-width: 1200px;
            height: 672px;
            background: #e9eaed;
            .content {
                padding-top: 48px;
                margin: 0 auto;
                width: 1200px;
                height: 592px;
                .main {
                    margin: 0 auto;
                    width: 913px;
                    display: flex;
                    justify-content: space-between;
                    .main-left {
                        margin-top: 59px;
                        width: 366px;
                        height: 444px;
                        .el-carousel--horizontal {
                            & /deep/ .el-carousel__container {
                                margin-bottom: 40px;
                            }
                            // & /deep/ .el-carousel__indicators {
                            //     & /deep/ .el-carousel__indicator--horizontal {
                                    
                            //     }
                            // }
                        }
                    }
                    .main-right {
                        position: relative;
                        padding-top: 30px;
                        padding-left: 40px;
                        padding-right: 40px;
                        box-sizing: border-box;
                        width: 380px;
                        height: 525px;
                        background: #FFFFFF;
                        border-radius: 6px;
                        .main-right-logo {
                            margin: 0 auto;
                            margin-bottom: 25px;
                            width: 169px;
                            height: 40px;
                            img {
                                width: 100%;
                                height: 100%;
                            }
                        }
                        .on {
                            display: none;
                        }
                        .input {
                            margin-bottom: 20px;
                            position: relative;
                            input {
                                width: 300px;
                                height: 50px;
                                border: 1px solid #DEDEDE;
                                padding-left: 37px;
                                box-sizing: border-box;
                            }
                            .input-icon {
                                position: absolute;
                                top: 17px;
                                left: 12px;
                                width: 16px;
                                height: 16px;
                                img {
                                    width: 100%;
                                    height: 100%;
                                }
                            }
                            .forgetIcon {
                                position: absolute;
                                top: 19px;
                                right: 10px;
                                width: 21px;
                                height: 14px;
                                cursor: pointer;
                                img {
                                    width: 100%;
                                    height: 100%;
                                }
                            }
                        }
                        .code {
                            margin-bottom: 20px;
                            display: flex;
                            position: relative;
                            .code-icon {
                                position: absolute;
                                top: 19px;
                                left: 12px;
                                width: 15px;
                                height: 13px;
                                img {
                                    width: 100%;
                                    height: 100%;
                                }
                            }
                            input {
                                width: 200px;
                                height: 50px;
                                border: 1px solid #DEDEDE;
                                padding-left: 37px;
                                box-sizing: border-box;
                            }
                            button {
                                width: 100px;
                                height: 52px;
                                background: #FF5A74;
                                border-radius: 0px 3px 3px 0px;
                                text-align: center;
                                line-height: 50px;
                                font-size: 14px;
                                font-family: Microsoft YaHei;
                                font-weight: 400;
                                color: #FFFFFF;
                                border: none;
                                cursor: pointer;
                            }
                            .input-icon {
                                position: absolute;
                                top: 17px;
                                left: 12px;
                                width: 16px;
                                height: 16px;
                                background: pink;
                            }
                        }
                        .checkConfim {
                            margin-bottom: 28px;
                            display: flex;
                            align-items: center;
                            .checkbox {
                                margin-top: 1px;
                                margin-right: 8px;
                            }
                            p {
                                font-size: 14px;
                                font-family: Microsoft YaHei;
                                font-weight: 400;
                                color: #666666;
                            }
                            a {
                                color: #FA4B66;
                            }
                        }
                        .login-button {
                            button {
                                margin-bottom: 14px;
                                width: 300px;
                                height: 50px;
                                background: #FA4B66;
                                font-size: 18px;
                                font-family: Microsoft YaHei;
                                color: #FFFFFF;
                                background-color:#FA4B66;
                                border:none;          
                                border-radius: 30px;
                                cursor: pointer;
                            }
                        }
                        .login-register {
                            position: absolute;
                            bottom: 0;
                            left: 0;
                            width: 380px;
                            text-align: center;
                            height: 50px;
                            line-height: 50px;
                            background: #F7F7F7;
                            border-radius: 0px 0px 6px 6px;
                            border-top: 1px solid #EEEEEE;
                            span {
                                font-size: 14px;
                                font-family: Microsoft YaHei;
                                font-weight: 400;
                                color: #333333;
                                .register {
                                    color: #FA4B66;
                                }
                            }
                            
                        }
                    }
                }
            }
        }
    }
</style>